<template>
  <navbar btn-background="bg-gradient-dark" />
  <section class="pt-5 page-header align-items-start bg-dark">
    <span class="mask opacity-8" style="background-color: #080808"></span>
    <div class="container">
      <div class="row">
        <div class="col-md-12 pt-6 pb-4 text-white">
          <h2 class="text-white">Terms of Use</h2>
          <p>Last updated: October 7th, 2024</p>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-6 py-5">
          <p>
            Welcome to ExaDrive (or ExaDrive Management Console). By accessing
            and using this site, you agree to comply with and be bound by the
            following terms and conditions of use, which govern our relationship
            with you regarding this website. If you disagree with any part of
            these terms, please do not use ExaDrive.
          </p>

          <h4>1. Age Restriction</h4>

          <p>
            ExaDrive is available for use by individuals who are 18 years of age
            or older. By using this service, you confirm that you are at least
            18 years old. Accounts found to be created by or for users under
            this age will be terminated immediately.
          </p>

          <h4>2. User Conduct</h4>

          <p>
            Users may upload and share public data on this platform. However,
            the following conduct is strictly prohibited:
          </p>

          <ul>
            <li>
              Uploading, distributing, or promoting content that contains
              profanity, explicit sexual content, pornography, or any form of
              obscenity.
            </li>
            <li>
              Posting or sharing content that promotes hate, racism, sexism, or
              any form of discriminatory behavior.
            </li>
            <li>
              Uploading or sharing material that promotes or glorifies violence,
              terrorism, or any criminal activities.
            </li>
            <li>
              Sharing personal information of individuals without their explicit
              consent.
            </li>
            <li>
              Distributing malware, viruses, or any harmful code that could harm
              users or the platform.
            </li>
            <li>
              Uploading or sharing content that infringes upon the intellectual
              property rights of others, including unauthorized use of
              copyrighted material.
            </li>
          </ul>

          <p>
            We reserve the right to remove any content that violates these
            guidelines and terminate user accounts in cases of repeated
            offenses.
          </p>

          <h4>3. Data Responsibility</h4>

          <p>
            Users are solely responsible for the content they upload and share.
            Ensure that all data complies with applicable local, national, and
            international laws. Users must avoid sharing data that may violate
            privacy rights, data protection laws, or contain confidential
            information.
          </p>

          <h4>
            4. Fair Usage Policy - Exa Protocol Accelerate Program and general
            use
          </h4>

          <p>
            The Exa Protocol Accelerate Program is committed to fostering
            innovation while maintaining a safe and ethical ecosystem.
            Participants in this program are expected to adhere to the following
            Fair Usage Policy:
          </p>

          <h4>Prohibited Content</h4>
          <ul>
            <li>
              Do not use ExaDrive or any Exa Protocol resources to store,
              distribute, or promote illegal content or materials.
            </li>
            <li>
              Explicit sexual content, pornography, or profane material is
              strictly prohibited.
            </li>
            <li>
              Hate speech, discriminatory content, or content that promotes
              violence or harm to any group or individual is forbidden.
            </li>
            <li>
              Sharing personal information without the explicit consent of the
              individual is not allowed.
            </li>
            <li>
              Participants must not distribute malware, viruses, or any other
              harmful software code.
            </li>
            <li>
              All content must respect intellectual property rights.
              Infringement of copyrighted materials is prohibited.
            </li>
          </ul>

          <h4>Ethical Development</h4>
          <p>
            Projects developed under the Exa Protocol Accelerate Program must
            adhere to ethical guidelines, ensuring that applications do not
            exploit vulnerabilities, cause harm, or infringe on privacy rights.
            We expect all participants to prioritize responsible development
            practices.
          </p>

          <h4>Data Responsibility</h4>
          <p>
            Participants are responsible for the data they store and share. All
            content must comply with applicable laws and regulations.
          </p>

          <h4>Security Measures</h4>
          <p>
            Participants must implement appropriate security measures to protect
            user data and prevent unauthorized access or breaches.
          </p>

          <h4>Content Moderation</h4>
          <p>
            Projects or platforms hosting user-generated content must implement
            effective content moderation systems to prevent the spread of
            harmful, illegal, or prohibited content.
          </p>

          <h4>Transparency</h4>
          <p>
            Participants must clearly communicate their project's purpose, data
            usage policies, and terms of service to end-users. Transparency in
            data usage and content distribution is mandatory.
          </p>

          <h4>Compliance</h4>
          <p>
            All participants must adhere to relevant local and international
            laws and regulations, particularly those concerning data protection,
            privacy, and content distribution.
          </p>

          <h4>Reporting Mechanism</h4>
          <p>
            Participants must provide a clear mechanism for users to report
            harmful or prohibited content. This system should allow for quick
            and effective responses to potential violations.
          </p>

          <h4>Regular Audits</h4>
          <p>
            Participants are expected to conduct regular audits of their
            projects to ensure ongoing compliance with this policy. Failure to
            comply may result in suspension or termination of participation in
            the Exa Protocol Accelerate Program.
          </p>

          <h4>5. Termination</h4>

          <p>
            We reserve the right to suspend or terminate user accounts that
            violate these terms of use. In cases of severe violations, legal
            actions may be taken, including reporting illegal content to law
            enforcement authorities.
          </p>

          <h4>6. Changes to Terms of Use</h4>

          <p>
            We may update these terms from time to time. It is your
            responsibility to review this page periodically for any changes.
            Continued use of the platform after any changes constitutes your
            acceptance of the updated terms.
          </p>

          <h4>7. Contact Information</h4>

          <p>
            If you have any questions or concerns regarding these terms, please
            contact us at support@exaprotocol.com .
          </p>

          <hr class="my-5" />
          <h5>Related:</h5>
          <a class="btn btn-outline-primary btn-sm" href="/privacy"
            >Read Privacy Policy</a
          >
        </div>
      </div>
    </div>
  </section>
  <app-footer />
</template>

<script>
import { mapMutations } from "vuex";
import Navbar from "@/components/PageLayout/Navbar.vue";
import AppFooter from "@/components/PageLayout/Footer.vue";
export default {
  name: "TermsOfUse",
  components: {
    Navbar,
    AppFooter,
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>
