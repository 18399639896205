<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h3>Settings</h3>
            <p class="text-sm">
              From this page you can update your organization profile. This
              profile is used to generate invoices in the name of the
              organization. You can also change you account password from here.
            </p>
            <p class="text-dark font-weight-bold text-sm" role="alert">
              <span class="fa fa-warning"></span> ExaDrive Management Console is
              currently open beta. Expect experimental features, ephimeral data
              storage, and CDN downtime.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-lg-3">
        <side-nav />
      </div>
      <div class="col-lg-9 mt-lg-0 mt-4">
        <side-nav-item />
      </div>
    </div>
  </div>
</template>

<script>
import NavPill from "./components/NavPill.vue";
import SideNav from "./components/SideNav.vue";
import SideNavItem from "./components/SideNavItem.vue";
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "Settings",
  components: {
    NavPill,
    SideNav,
    SideNavItem,
  },
  mounted() {
    this.$store.state.isAbsolute = false;
    this.$store.state.isNavFixed = false;
    setNavPills();
  },
};
</script>
