<template>
  <div class="container-fluid mt-3 pt-2 dashboard-container-fluid">
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h3>Buckets</h3>
            <p class="text-sm">
              This page lists all the buckets automatically created for your
              active Apps. Each bucket is tied to a specific app, identified by
              its name, ID, and custom CDN link. You can click “View Bucket” to
              access the contents of each bucket, ensuring easy management of
              app-specific data storage
            </p>
            <p class="text-dark font-weight-bold text-sm" role="alert">
              <span class="fa fa-warning"></span> ExaDrive Management Console is
              currently open beta. Expect experimental features, ephimeral data
              storage, and CDN downtime.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5 mt-4">
      <div class="col-12 d-flex justify-content-center mt-8" v-if="loading">
        <Spinner />
      </div>

      <template v-if="apps.length == 0 && !loading">
        <div class="col-12 text-center" style="margin-top: 50px">
          <font-awesome-icon icon="fa-solid fa-circle-info" class="fa-2x" />
          <h4>There are no Buckets.</h4>
          <p>
            Buckets are automatically created for your apps. To create a Bucket
            create an App.
          </p>
          <button
            class="btn btn-primary"
            @click="this.$router.push('/apps/create')"
          >
            + Create App
          </button>
        </div>
      </template>
      <template v-else>
        <div v-for="app in apps" :key="app._id" class="col-md-3">
          <BucketCard
            :appName="app.appName"
            :active="app.active"
            :appDescription="app.appDescription"
            :appId="app._id"
            :appIndentifier="app.appIdentifier"
            :value="2000"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Paginator from "primevue/paginator";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; // optional
import Row from "primevue/row"; // optional
import setNavPills from "@/assets/js/nav-pills.js";
import BucketCard from "./components/BucketCard.vue";
import axios from "axios";
import Avatar from "vue-boring-avatars";
import Spinner from "../../components/Spinner.vue";
export default {
  name: "Buckets",
  components: {
    DataTable,
    Column,
    ColumnGroup,
    Row,
    Paginator,
    BucketCard,
    Avatar,
    Spinner,
  },
  mounted() {
    // this.$store.state.isAbsolute = false;
    // this.$store.state.isNavFixed = false;
    // setNavPills();

    //fetch apps
    axios
      .get(this.$store.getters["getServerURL"] + "/org/apps/getAllApps", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        // console.log(response);
        this.loading = false;
        this.apps = response.data;

        this.apps.reverse();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      apps: [],
      loading: true,
    };
  },
};
</script>

<style></style>
