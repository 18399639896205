<template>
  <div class="container-fluid mt-3 py-4 bg-gradient-dark">
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p>
              <a href="/admin"
                ><i class="fa fa-arrow-left"></i> Back to Admin</a
              >
            </p>
            <p>Administration / All Users</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5 mt-4">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h5>
              <i class="fa fa-info-circle"></i> Total {{ users.length }} users.
            </h5>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Active</th>

                    <th>Created At</th>
                    <th>Activation</th>
                    <th>Manage Credits</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <UserTableRow
                    v-for="user in users"
                    :key="user._id"
                    :user="user"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import axios from "axios";
import UserTableRow from "./components/UserTableRow.vue";

export default {
  name: "AdminUsers",
  components: {
    UserTableRow,
  },
  mounted() {
    this.$store.state.isAbsolute = false;
    this.$store.state.isNavFixed = false;
    setNavPills();

    //fetch users
    axios
      .get(this.$store.getters["getServerURL"] + "/admin/getAllUsers", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        this.users = response.data;
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      users: [],
    };
  },
};
</script>

<style>
.quick-links {
  list-style: none;
  padding: 0;
}

.quick-links li {
  padding: 10px;
}

.quick-links li a:hover {
  color: initial;
}
.quick-links li:hover {
  background: #eee;
}
</style>
