<template>
  <div>
    <sidenav v-if="showSidenav" :custom-class="color" class="fixed-start" />
    <main
      class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    >
      <!-- nav -->
      <navbar
        v-if="showNavbar"
        :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
        :min-nav="navbarMinimize"
      />
      <router-view />
      <app-footer v-show="showFooter" />
    </main>
  </div>
</template>
<script>
import Sidenav from "@/components/Sidenav";
import Navbar from "@/components/Navbars/Navbar.vue";
import AppFooter from "@/components/PageLayout/Footer.vue";
import { mapMutations, mapState } from "vuex";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
    AppFooter,
  },

  computed: {
    ...mapState([
      "isTransparent",
      "isRTL",
      "isNavFixed",
      "isAbsolute",
      "navbarFixed",
      "absolute",
      "color",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-white";
  },
  mounted() {
    AOS.init();
  },

  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
};
</script>
<style>
.card {
  border-radius: 0 !important;
  border: 1px solid #ccc !important;
}

@-webkit-keyframes blue-fade {
  from {
    background: rgb(201, 223, 255);
  }
  to {
    background: #fff;
  }
}
@-moz-keyframes blue-fade {
  from {
    background: rgb(201, 223, 255);
  }
  to {
    background: #fff;
  }
}
@keyframes blue-fade {
  from {
    background: rgb(201, 223, 255);
  }
  to {
    background: #fff;
  }
}
.fade-it {
  -webkit-animation: blue-fade 1s ease-in-out 0s;
  -moz-animation: blue-fade 1s ease-in-out 0s;
  -o-animation: blue-fade 1s ease-in-out 0s;
  animation: blue-fade 1s ease-in-out 0s;
}

.dashboard-container-fluid {
  min-height: 80vh;
}
.p-toast {
  z-index: 1000;
}
</style>
