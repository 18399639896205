<template>
  <div class="container-fluid mt-3 py-4 pb-6 bg-gradient-dark">
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p>
              <a href="/admin"
                ><i class="fa fa-arrow-left"></i> Back to Admin</a
              >
            </p>
            <p>Administration / Summary</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mt-4">
        <h4 class="text-white"><i class="fa fa-user"></i> Users and Apps</h4>
      </div>

      <div
        class="col-md-2 mt-4"
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-delay="0"
      >
        <DefaultCounterCard
          :count="summary.countLifetimeApps || 0"
          cardColor="white"
          color="dark"
          suffix=""
          title="Lifetime Total Apps"
          description="All Apps"
        />
      </div>
      <div
        class="col-md-2 mt-4"
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-delay="200"
      >
        <DefaultCounterCard
          :count="summary.countApps || 0"
          cardColor="white"
          color="dark"
          suffix=""
          title="Active Apps"
          description="Only active apps"
        />
      </div>
      <div
        class="col-md-2 mt-4"
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-delay="400"
      >
        <DefaultCounterCard
          :count="summary.countUsers || 0"
          cardColor="white"
          color="dark"
          suffix=""
          title="Total Users"
          description="Users all time (Inactive+Active)"
        />
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <h4 class="text-white"><i class="fa fa-file"></i> File Metrics</h4>
      </div>
      <div
        class="col-md-2 mt-4"
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-delay="0"
      >
        <DefaultCounterCard
          :count="summary.fileLifetimeCount || 0"
          cardColor="white"
          color="dark"
          suffix=""
          title="Lifetime Total Files"
          description="All Time"
        />
      </div>
      <div
        class="col-md-2 mt-4"
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-delay="200"
      >
        <DefaultCounterCard
          :count="summary.fileCount || 0"
          cardColor="white"
          color="dark"
          suffix=""
          title="Total Files"
          description="Not Deleted"
        />
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <h4 class="text-white"><i class="fa fa-chart-bar"></i> Data Metrics</h4>
      </div>

      <div
        class="col-md-2 mt-4"
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-delay="0"
      >
        <DefaultCounterCard
          :count="formatBytes(summary.dataEgressAllTime).num || 0"
          cardColor="white"
          color="dark"
          :suffix="formatBytes(summary.dataEgressAllTime).suffix"
          title="Total Data Egress"
          description="All Time"
        />
      </div>
      <div
        class="col-md-2 mt-4"
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-delay="200"
      >
        <DefaultCounterCard
          :count="formatBytes(summary.dataIngressAllTime).num || 0"
          cardColor="white"
          color="dark"
          :suffix="formatBytes(summary.dataIngressAllTime).suffix"
          title="Total Data Ingress"
          description="All Time"
        />
      </div>
      <div
        class="col-md-2 mt-4"
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-delay="400"
      >
        <DefaultCounterCard
          :count="formatBytes(summary.dataEgressThisMonth).num || 0"
          cardColor="white"
          color="dark"
          :suffix="formatBytes(summary.dataEgressThisMonth).suffix"
          title="Data Egress"
          description="This Month"
        />
      </div>
      <div
        class="col-md-2 mt-4"
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-delay="600"
      >
        <DefaultCounterCard
          :count="formatBytes(summary.dataIngressThisMonth).num || 0"
          cardColor="white"
          color="dark"
          :suffix="formatBytes(summary.dataIngressThisMonth).suffix"
          title="Data Ingress"
          description="This Month"
        />
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <h4 class="text-white"><i class="fa fa-bolt"></i> Performance</h4>
      </div>
      <div
        class="col-md-3 mt-4"
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-delay="0"
      >
        <DefaultCounterCard
          :count="formatBytes(summary.avgDataEgressRateThisMonth).num || 0"
          cardColor="white"
          :alwaysCount="false"
          color="dark"
          :suffix="
            formatBytes(summary.avgDataEgressRateThisMonth).suffix + '/sec'
          "
          title="Egress Rate"
          description="This month"
        />
      </div>

      <div
        class="col-md-3 mt-4"
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-delay="200"
      >
        <DefaultCounterCard
          :count="formatBytes(summary.avgDataIngressRateThisMonth).num || 0"
          cardColor="white"
          :alwaysCount="false"
          color="dark"
          :suffix="
            formatBytes(summary.avgDataIngressRateThisMonth).suffix + '/sec'
          "
          title="Ingress Rate"
          description="This month"
        />
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <h4 class="text-white"><i class="fa fa-money-bill"></i> Credits</h4>
      </div>
      <div
        class="col-md-3"
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-delay="0"
      >
        <DefaultCounterCard
          :count="parseFloat(summary.worthOfCreditRemaining).toFixed(4) || 0"
          :alwaysCount="false"
          cardColor="white"
          color="dark"
          suffix="$XAP"
          title="Total Credits Remaining"
          description="Accross all users"
        />
      </div>
    </div>
  </div>
</template>

count: { type: [String, Number], required: true, }, cardColor: { type: String,
default: "", }, suffix: { type: String, default: "", }, title: { type: String,
required: true, }, description: { type: String, default: "", }, color: { type:
String, default: "success", },

<script>
import axios from "axios";
import DefaultCounterCard from "../../../examples/Cards/DefaultCounterCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
export default {
  name: "AdminSummary",
  components: {
    DefaultCounterCard,
  },
  data() {
    return {
      summary: {},
    };
  },
  mounted() {
    this.$store.state.isAbsolute = false;
    this.$store.state.isNavFixed = false;
    setNavPills();

    //getUser
    axios
      .get(this.$store.getters["getServerURL"] + "/admin/getSummary", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        this.summary = response.data;
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    formatBytes(bytes, decimals = 2) {
      console.log(bytes);
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return {
        num: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
        suffix: sizes[i],
      };
    },
  },
};
</script>
