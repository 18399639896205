<template>
  <div class="card fade-it" data-aos="fade-up" data-aos-once="true">
    <div class="p-3 card-body">
      <div class="d-flex">
        <div class="p-2 avatar avatar-xl bg-gradient-dark border-radius-md">
          <Avatar :size="80" variant="pixel" :name="title" />
        </div>
        <div class="my-auto ms-3">
          <h6>
            {{ title }} -

            <span v-if="active == 0" class="badge badge-warning badge-sm"
              >Inactive</span
            >
            <span v-if="active == 1" class="badge badge-primary badge-sm"
              >Active</span
            >
            <span v-if="active == 2" class="badge badge-danger badge-sm"
              >Suspended</span
            >
          </h6>

          <p class="text-xs text-muted mt-0">
            <span class="font-weight-bold">CDN:</span>
            {{ appIdentifier }}.exadrivecdn.com
          </p>
        </div>
        <div class="ms-auto">
          <div class="dropdown">
            <button
              id="navbarDropdownMenuLink"
              class="btn btn-link text-secondary ps-0 pe-2"
              :class="{ show: showMenu }"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="text-lg fa fa-cog"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3"
              :class="{ show: showMenu }"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <a
                class="dropdown-item border-radius-md"
                data-bs-toggle="modal"
                :data-bs-target="'#apiKeyModal' + appID"
                >View API Key
              </a>
              <a
                class="dropdown-item border-radius-md"
                data-bs-toggle="modal"
                :data-bs-target="'#updateAppModal' + appID"
                >Update App</a
              >
              <a
                class="dropdown-item border-radius-md"
                href="#"
                @click="drawerVisible = true"
                >Settings</a
              >
            </div>
          </div>
        </div>
      </div>
      <hr class="horizontal dark" />
      <p class="mt-3 text-sm">
        <span class="font-weight-bolder">Description:</span>
        {{ description }}
      </p>
      <hr class="horizontal dark" />
      <div class="row">
        <div class="col-6">
          <p class="mb-0 text-xs text-secondary">Last Updated</p>
          <h6 class="mb-0 text-sm">{{ getDate(parseInt(lastUpdated)) }}</h6>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <button
            class="btn btn-link btn-sm px-2"
            @click="this.$router.push('/buckets/filemanager/' + appID)"
          >
            View Bucket
          </button>
        </div>
      </div>
      <Toast />
    </div>
  </div>

  <!-- Drawer -->
  <Drawer
    v-model:visible="drawerVisible"
    :header="title"
    position="right"
    class="w-30-rem"
  >
    <div class="row py-2 border-bottom border-top">
      <div class="col">
        <h6>Generate new API Key</h6>
        <p class="text text-sm">
          Once a new API Key is generated the previous API Key will stop working
          immediately. This action affects the functioning of SDK. You will have
          to setup the SDK with the new API Key.
        </p>

        <ConfirmPopup></ConfirmPopup>
        <button
          type="button"
          class="btn btn-primary btn-sm mt-3"
          @click="confirm1($event)"
          id="generateAPIKeyButton"
        >
          Generate new API Key
        </button>
      </div>
    </div>
    <div class="row py-2 border-bottom">
      <div class="col">
        <h6>Valid Origins <i> - Coming Soon</i></h6>
        <p class="text-sm">
          Enter hostnames from which the GET requests can originate to the CDN.
        </p>
      </div>
    </div>
    <div class="row py-2 border-bottom">
      <div class="col">
        <h6>Permanently delete app</h6>
        <p class="text-sm">
          Delete the app and all associated files. This action cannot be undone.
        </p>
        <a
          class="btn btn-danger btn-sm mt-3"
          data-bs-toggle="modal"
          :data-bs-target="'#deleteAppModal' + appID"
          >Delete App
        </a>
      </div>
    </div>
  </Drawer>

  <!-- Update App name and description Modal -->
  <div
    class="modal"
    :id="'updateAppModal' + appID"
    aria-labelledby="updateAppModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="text-dark text-xs text-center mb-0 pb-0">
            App Identifier: {{ appIdentifier }}
          </p>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="fa fa-close"></span>
          </button>
        </div>
        <div class="modal-body py-4">
          <div class="mb-2 pr-5">
            <label for="appname" class="form-label">New App Name</label>
            <input
              id="appnem"
              type="text"
              class="form-control"
              placeholder=""
              v-model="appName"
            />
          </div>
          <div class="mb-2 pr-5">
            <label for="appdesc" class="form-label">New App Description</label>
            <input
              id="appdesc"
              type="text"
              class="form-control"
              placeholder=""
              v-model="appDesc"
            />
          </div>
          <p class="text-xs mt-3">
            <span class="fa fa-info-circle"></span>&nbsp; You cannot change the
            App ID and App Identifier.
          </p>
          <div class="mt-4 pr-5">
            <button class="btn btn-primary btn-sm" @click="updateApp()">
              Update App
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- View APIKey Modal -->
  <div
    class="modal"
    :id="'apiKeyModal' + appID"
    aria-labelledby="apiKeyModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="fa fa-close"></span>
          </button>
        </div>
        <div class="modal-body text-center py-5">
          <p class="text-sm text-danger">
            <font-awesome-icon icon="triangle-exclamation" /> Keep your API key
            safe. <br />
            Sharing this API Key will grant access to your App and all of its
            files.
          </p>
          <hr />
          <Inplace :active="showAPIKey">
            <template #display>Click to view API Key</template>
            <template #content>
              <code class="text-dark font-weight-bold"
                >App ID: {{ appID }}
              </code>
              <br />
              <code class="text-dark font-weight-bold"
                >API Key: {{ apiKey }}</code
              >

              <p class="text-center font-weight-bold text-xs mt-4">
                Generated on: <br />{{ getDate(parseInt(apiKeyGenTime)) }}
              </p>
            </template>
          </Inplace>
        </div>
      </div>
    </div>
  </div>

  <!-- Delete App Modal -->
  <div
    class="modal"
    :id="'deleteAppModal' + appID"
    aria-labelledby="deleteAppModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="">Delete App?</h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body py-5 px-5">
          <h5 class="font-weight-bold text-danger text-center">
            <font-awesome-icon icon="triangle-exclamation" /> Warning!
          </h5>
          <p class="text-center">
            You are about to permanently delete
            <span class="text-danger">{{ title }}!</span> <br />Once deletion is
            initiated you will not be able to undo it. <br />
            Files associated with this App will be permanently deleted and
            cannot be recovered.
            <br />
          </p>
          <p class="font-weight-bold mt-4 pt-4">
            Type the App name ({{ title }}) below to confrim:
          </p>
          <input
            class="form-control"
            type="text"
            name=""
            v-model="confirmText"
            placeholder="Enter app name..."
          />
          <p class="text-danger text-sm" v-if="confirmError">
            You must enter the "App name" above to confirm deleteion.
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button type="button" class="btn btn-danger" @click="deleteApp()">
            Delete App
          </button>
        </div>
      </div>
    </div>
  </div>

  <Toast />
</template>

<script>
import ConfirmPopup from "primevue/confirmpopup";

import axios from "axios";
import Inplace from "primevue/inplace";
import Avatar from "vue-boring-avatars";
import Drawer from "primevue/drawer";
import Toast from "primevue/toast";
export default {
  name: "AppCard",
  components: {
    Inplace,
    Avatar,
    Drawer,
    Toast,
    ConfirmPopup,
  },
  props: {
    appIdentifier: {
      type: String,
      default: "",
    },
    active: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    appID: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    lastUpdated: {
      type: String,
      default: "",
    },
    apiKey: {
      type: String,
      default: null,
    },
    apiKeyGenTime: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      appDesc: this.description,
      appName: this.title,
      showMenu: false,
      confirmText: "",
      confirmError: false,
      drawerVisible: false,
      showAPIKey: false,
    };
  },
  methods: {
    getDate(UNIX_timestamp) {
      var a = new Date(UNIX_timestamp);
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var finetime = a.getHours() + ":" + a.getMinutes() + ":" + a.getSeconds();

      var time = date + " " + month + " " + year + " " + finetime;
      return time;
    },
    deleteApp() {
      this.confirmError = false;
      if (this.title != this.confirmText) {
        this.confirmError = true;
        return;
      }
      axios({
        method: "delete",
        url:
          this.$store.getters["getServerURL"] +
          "/org/apps/deleteApp/" +
          this.appID,
        headers: { Authorization: localStorage.getItem("jwt") },
      })
        .then((response) => {
          // console.log(response);
          location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "Error while deleting the app. \n" + err.response.data.message,
            life: 5000,
          });
        });
    },
    confirm1(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Are you sure you want to proceed?",
        icon: "fa fa-exclamation-triangle",
        rejectProps: {
          label: "Cancel",
          severity: "secondary",
          outlined: true,
        },
        acceptProps: {
          label: "Yes",
          severity: "secondary",
        },
        accept: () => {
          this.generateAPIKey();
        },
        reject: () => {},
      });
    },
    generateAPIKey() {
      const generateAPIKeyButton = document.getElementById(
        "generateAPIKeyButton"
      );
      generateAPIKeyButton.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Loading...`;
      generateAPIKeyButton.disabled = true;
      axios({
        method: "post",
        url:
          this.$store.getters["getServerURL"] +
          "/org/apps/generateAPIKey/" +
          this.appID,
        headers: { Authorization: localStorage.getItem("jwt") },
      })
        .then((response) => {
          console.log(response.data);
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "API Key generated. Reloading page...",
            life: 2000,
          });
          generateAPIKeyButton.innerHTML = `Generate API Key`;

          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
            generateAPIKeyButton.disabled = false;
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          generateAPIKeyButton.innerHTML = `Generate New API Key`;
          generateAPIKeyButton.disabled = false;
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Unable to generate the API Key. Try again later.",
            life: 2000,
          });
        });
    },
    updateApp() {
      axios({
        method: "post",
        url:
          this.$store.getters["getServerURL"] +
          "/org/apps/updateApp/" +
          this.appID,
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          appName: this.appName,
          appDescription: this.appDesc,
        },
      })
        .then((resp) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "App updated! Reloading page...",
            life: 2000,
          });
          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "Error while updating the app. \n" +
              err.response.data.errors[0].msg +
              (err.response.data.errors[1]
                ? "\n" + err.response.data.errors[1].msg
                : ""),
            life: 5000,
          });
        });
    },
    openDrawer() {
      this.drawerVisible = true;
    },
  },
};
</script>

<style>
.w-30-rem {
  width: 30rem !important;
}
</style>
