<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
          nav-text="Dashboard"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="/dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
        >
          <template #icon>
            <Office />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          nav-text="Apps"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="/apps"
          :class="getRoute() === 'apps' ? 'active' : ''"
        >
          <template #icon>
            <Box3d />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          nav-text="Buckets"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="/buckets"
          :class="getRoute() === 'buckets' ? 'active' : ''"
        >
          <template #icon>
            <Document />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          nav-text="Billing"
          :collapse="false"
          collapse-ref="/billing"
          :class="getRoute() === 'billing' ? 'active' : ''"
        >
          <template #icon>
            <Settings />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          nav-text="Settings"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="/account/settings"
          :class="getRoute() === 'account' ? 'active' : ''"
        >
          <template #icon>
            <Switches />
          </template>
        </sidenav-collapse>
      </li>

      <hr class="horizontal dark" />

      <li class="nav-item">
        <sidenav-collapse
          nav-text="Get the SDK"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="https://www.npmjs.com/package/exadrive-sdk"
          target="_blank"
        >
          <template #icon>
            <Box3d />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          nav-text="Documentation"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="https://exa-drive.gitbook.io/exa-drive-docs"
          target="_blank"
        >
          <template #icon>
            <Document />
          </template>
        </sidenav-collapse>
      </li>
    </ul>
    <hr class="horizontal dark" v-if="isAdmin" />
    <ul class="navbar-nav" v-if="isAdmin">
      <li class="nav-item">
        <sidenav-collapse
          nav-text="Admin"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="/admin"
          :class="getRoute() === 'admin' ? 'active' : ''"
        >
          <template #icon>
            <Office />
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
  <hr class="horizontal dark" />
  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :class="cardBg"
      text-primary="ExaDrive Grants"
      text-secondary="Get upto 1000 $XAP!"
      route="https://forms.gle/KXmK68rQBfdpWKX6A"
      label="Apply Now"
      icon="ni ni-diamond"
    />
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCard from "./SidenavCard.vue";
import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import Settings from "../../components/Icon/Settings.vue";
import Switches from "../../components/Icon/Switches.vue";
import Basket from "../../components/Icon/Basket.vue";
import Box3d from "../../components/Icon/Box3d.vue";
import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import Document from "../../components/Icon/Document.vue";
import Spaceship from "../../components/Icon/Spaceship.vue";
import CreditCard from "../../components/Icon/CreditCard.vue";

import { mapState } from "vuex";
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavCard,
    SidenavCollapseItem,
    Settings,
    Switches,
    Basket,
    Box3d,
    Shop,
    Office,
    Document,
    Spaceship,
    CreditCard,
  },
  props: {
    cardBg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isAdmin: false,
    };
  },
  mounted() {
    // console.log(this.$store.getters["getServerURL"]);
    if (localStorage.getItem("role") == 1) {
      this.isAdmin = true;
    }
  },
  computed: {
    ...mapState(["isRTL"]),
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
<style>
.navbar-vertical .navbar-nav > .nav-item .nav-link.active .icon {
  background-image: linear-gradient(
    310deg,
    #1d59ff 0%,
    #2b40ff 100%
  ) !important;
}
</style>
