<template>
  <div class="card mb-4" data-aos="fade-up">
    <div class="mx-4 card-header d-flex justify-content-center">
      <Avatar :size="80" variant="pixel" :name="appName" />
    </div>
    <hr class="my-2 horizontal dark" />
    <div class="px-3 py-0 card-body text-center">
      <span v-if="active == 0" class="badge badge-warning badge-sm"
        >Inactive</span
      >
      <span v-if="active == 1" class="badge badge-primary badge-sm"
        >Active</span
      >
      <span v-if="active == 2" class="badge badge-danger badge-sm"
        >Suspended</span
      >
      <h6 class="mb-0 text-center">{{ appName }}</h6>
      <p class="text-xs">{{ appId }}</p>
      <code class="text-xs">{{ appIndentifier }}.exadrivecdn.com</code>

      <hr class="my-2 horizontal dark" />
      <a
        class="btn btn-link"
        @click="this.$router.push('/buckets/filemanager/' + appId)"
        >View Bucket</a
      >
    </div>
  </div>
</template>

<script>
import Avatar from "vue-boring-avatars";

export default {
  name: "BucketCard",
  components: {
    Avatar,
  },
  props: {
    appName: {
      type: String,
      required: true,
    },
    active: {
      type: Number,
      default: 1,
    },
    appId: {
      type: String,
    },
    appIndentifier: {
      type: String,
      default: "",
    },
    appDescription: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
};
</script>
