<template>
  <div class="container-fluid dashboard-container-fluid">
    <section class="mt-3 pt-2">
      <div class="row mt-3">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h3>Apps</h3>
              <p class="text-sm">
                This page displays all the apps associated with your account.
                Each card represents an app with its unique ID, identifier, and
                description. You can view the associated storage bucket for each
                app through the “View Bucket” link. The page also allows the
                creation of new apps by clicking the “New App” card.
              </p>
              <p class="text-dark font-weight-bold text-sm" role="alert">
                <span class="fa fa-warning"></span> ExaDrive Management Console
                is currently open beta. Expect experimental features, ephimeral
                data storage, and CDN downtime.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div
          class="col-12 mt-10 mb-10 d-flex justify-content-center"
          v-if="loading"
        >
          <Spinner />
        </div>
        <template v-if="!loading">
          <div
            class="mb-4 col-lg-4 col-md-4"
            v-for="app in apps"
            :key="app._id"
          >
            <app-card
              :active="app.active"
              :appID="app._id"
              :title="app.appName"
              :description="app.appDescription"
              :lastUpdated="app.updatedOn"
              :apiKey="app.apiKey"
              :apiKeyGenTime="app.apiKeyGenTime"
              :appIdentifier="app.appIdentifier"
            />
          </div>

          <div
            class="mb-4 col-lg-4 col-md-4"
            v-if="apps.length < 5"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <place-holder-card :title="{ text: 'New App', variant: 'h5' }" />
          </div>

          <div
            class="mb-4 col-lg-4 col-md-4 d-flex align-items-center justify-content-center"
            v-if="apps.length == 5"
            data-aos="fade-up"
            data-aos-delay="700"
          >
            <p class="alert alert-light">
              <i class="fa fa-info-circle"></i> Max. 5 Apps allowed in open
              beta.
            </p>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import PlaceHolderCard from "./components/PlaceHolderCard.vue";
import AppCard from "./components/AppCard.vue";
import Avatar from "vue-boring-avatars";
import axios from "axios";
import Spinner from "../../components/Spinner.vue";
export default {
  name: "Apps",
  components: {
    AppCard,
    PlaceHolderCard,
    Avatar,
    Spinner,
  },
  data() {
    return {
      showMenu: false,
      apps: [],
      loading: true,
    };
  },

  mounted() {
    // this.$store.state.isAbsolute = false;
    // this.$store.state.isNavFixed = false;
    // setNavPills();

    //fetch all apps
    axios
      .get(this.$store.getters["getServerURL"] + "/org/apps/getAllApps", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        // console.log(response);
        this.apps = response.data;
        this.apps.reverse();
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
