<template>
  <div class="card mt-4">
    <div class="card-body d-flex p-0">
      <div
        v-if="credits.length == 0"
        class="w-100 text-center justify-content-center align-self-center p-5"
      >
        <h2 class="fa fa-info-circle"></h2>
        <p>
          No credits added to this account. <br />You can apply for grants to
          get credits.
        </p>
        <a
          href="https://forms.gle/KXmK68rQBfdpWKX6A"
          target="_blank"
          class="btn btn-primary"
          >Apply Now</a
        >
      </div>
      <div class="table-responsive w-100" v-else>
        <table class="table table-striped mb-0">
          <thead class="bg-primary text-white">
            <th>Credit Description</th>
            <th>Credits Left</th>
          </thead>
          <tbody>
            <tr v-for="credit in credits" :key="credit._id">
              <td class="px-4 font-weight-bold">
                {{ credit.creditType.description || "Credits" }}
                <br />
                <span class="text-xs text-secondary"
                  >Added On:
                  {{ formatDate(credit.addedOn) }}
                  (dd/mm/yyy)</span
                >
                <br />
                <span class="text-xs text-secondary"
                  >Expires On:
                  {{ formatDate(credit.creditType.expiresOn) }}
                  (dd/mm/yyy)</span
                >
              </td>
              <td class="px-4 font-weight-bold">
                {{ parseFloat(credit.creditType.credits).toFixed(5) }} $XAP
                <br />
                <span class="text-xs text-primary"
                  >Allotted: {{ credit.creditType.allotted }} $XAP</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "Credits",
  components: {
    SoftButton,
  },
  props: {
    credits: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    formatDate(unixTimestamp) {
      var date = new Date(parseInt(unixTimestamp));
      return date.toLocaleDateString("en-GB");
    },
  },
};
</script>
