<template>
  <tr v-for="file in files" :key="file._id" class="fade-it">
    <td class="px-4" scope="row" :title="file.originalName">
      <div class="row">
        <div class="col-1 py-1">
          <span :class="mimeTypeToIcon(file.fileDetails.mimetype)"></span>
        </div>
        <div class="col-11">
          <span class="font-weight-bold">{{ file.originalName }} </span><br />
          <span class="text-xs text-primary">ID: {{ file._id }}</span>
        </div>
      </div>
    </td>
    <td scope="row" class="px-4">
      {{ file.virtualPath }}
    </td>

    <td scope="row" class="px-4 text-sm" style="width: 5%">
      {{ formatBytes(file.fileDetails.size, 2) }}
    </td>
    <td scope="row" class="px-4" style="width: 5%">
      <span class="badge badge-primary badge-sm">{{ file.isPublic }}</span>
    </td>

    <td
      scope="row"
      class="px-4 text-sm"
      style="width: 15%"
      :title="file.uploadedOn"
    >
      {{ file.uploadedOn }}
    </td>
    <td style="width: 5%; border-left: 1px solid #ddd">
      <div class="form-check form-switch">
        <input
          class="form-check-input mx-auto"
          type="checkbox"
          id="flexSwitchCheckDefault"
          :checked="file.enableCDN"
          v-on:input="this.$parent.toggleCDN(file._id, file.enableCDN)"
        />
      </div>
    </td>
    <td class="px-4" style="width: 5%">
      <button
        class="btn btn-link btn-sm mb-0 px-0"
        @click="this.$parent.copyText(file.publicLink)"
      >
        <span class="fa fa-copy"></span> Copy URL
      </button>
    </td>
    <td scope="row" class="px-4" style="width: 5%">
      <button
        class="btn btn-link text-danger mb-0 px-0"
        @click="
          this.$parent.deleteFile(file._id, file.originalName, file.virtualPath)
        "
      >
        <font-awesome-icon icon="fa-solid fa-trash" /> Delete
      </button>
    </td>
  </tr>
</template>
<script>
import axios from "axios";
export default {
  name: "FileRow",
  props: {
    files: {
      type: Array,
    },
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    mimeTypeToIcon(mimeType) {
      switch (mimeType) {
        case "application/pdf":
          return "fa fa-file-pdf";
        case "image/jpeg":
          return "fa fa-file-image ";
        case "image/jpg":
          return "fa fa-file-image ";
        case "image/svg+xml":
          return "fa fa-shapes ";
        case "image/png":
          return "fa fa-file-image ";
        case "image/gif":
          return "fa fa-file-image ";
        case "text/html":
          return "fa fa-html5";
        case "text/css":
          return "fa fa-css3";
        case "application/x-javascript":
          return "fa fa-code";
        default:
          return "fa fa-file";
      }
    },
  },
};
</script>
