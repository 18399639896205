<template>
  <navbar btn-background="bg-gradient-dark" />

  <!-- WELCOME -->
  <section
    class="pt-5 page-header align-items-start min-vh-50 pb-6 bg-dark vh-100"
    id="hero-section"
    :style="{
      'background-image': 'url(' + BGGrid + ')',
      'background-size': 'cover',
    }"
  >
    <span class="mask opacity-9" style="background-color: #070707"></span>
    <div class="container h-100 py-4">
      <div class="row align-items-center h-100">
        <div class="col-md-6 mt-3 pt-5" data-aos="fade-up" data-aos-once="true">
          <!-- Heading -->
          <img src="rounded.png" class="img-fluid hero-logo mb-4" alt="Logo" />
          <h1 class="display-3 text-md-start text-white">
            Start building on ExaDrive today.
          </h1>

          <!-- Text -->
          <p class="text-md-start text-white text-lg mt-5">
            Powered by a distributed file storage (DFS) built using mobile
            devices. <br />Store public files for your Web3 Projects, host
            static websites, or use it as a CDN.
          </p>

          <!-- Buttons -->
          <div class="text-md-start mt-4">
            <a
              href="/authentication/signup"
              class="btn btn-primary btn-lg shadow lift me-1"
            >
              Get Started
              <i class="fa fa-arrow-right d-none d-md-inline ms-3"></i>
            </a>
            &nbsp;
            <a
              href="https://forms.gle/KXmK68rQBfdpWKX6A"
              class="btn btn-lg btn-light lift"
              target="_blank"
            >
              Apply for Grants
            </a>
          </div>
        </div>
        <div
          class="col-md-6 justify-content-center d-none d-sm-block"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-once="true"
        >
          <Globe width="700" height="700" />
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <!-- FEATURES -->
  <section
    class="py-7 border-bottom bg-white"
    :style="{
      'background-image': 'url(' + BGFeatures + ')',
      'background-repeat': 'no-repeat',
      'background-size': 'contain',
      'background-position': 'center',
    }"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12 my-4">
          <h2 class="">ExaDrive Features</h2>
          <p class="">
            Unlock the true power of Decentralized Storage with ExaDrive
          </p>
        </div>
      </div>
      <div class="row text-center">
        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-5 bg-body rounded">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-bolt"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Web2 Speeds in a DFS</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-0 text-md">
                ExaDrive brings the speed and performance of traditional Web2
                cloud storage to the decentralized world of Web3. Leveraging a
                powerful Content Delivery Network (CDN), ExaDrive ensures that
                your files are quickly served, just like in conventional cloud
                solutions.
              </p>
            </div>
          </div>
        </div>

        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-4 bg-body rounded">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-globe"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Host Static Websites</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-0 text-md">
                ExaDrive allows you to host static websites seamlessly on its
                decentralized file system. Your static assets, like HTML, CSS,
                and JavaScript files, are served quickly and efficiently to
                users worldwide. Your website is securely distributed across
                mobile nodes, ensuring redundancy and resilience.
              </p>
            </div>
          </div>
        </div>

        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-4 bg-body rounded">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-money"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Optimized Costs</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-0 text-md">
                ExaDrive offers cost-effective storage by utilizing
                decentralized mobile nodes instead of expensive centralized
                server farms. This unique approach reduces infrastructure
                overhead, allowing you to store and serve static website assets
                more affordably.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-delay="600"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-5 bg-body rounded">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-code"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Built for developers</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-6 mb-md-0 text-md">
                Get started quickly with our developer-friendly SDKs. Designed
                to simplify integration, our tools allow you to focus on
                building great solutions, not setup. Whether you're creating for
                mobile, web, or cloud, we've got everything you need.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-5 bg-body rounded">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-box"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Storage on Mobile Nodes</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-0 text-md">
                ExaDrive’s unique mobile-first approach guarantees that files
                are securely and permanently stored on a decentralized network
                of mobile devices. This not only enhances security but also
                ensures that your data is always accessible and protected from
                central points of failure.
              </p>
            </div>
          </div>
        </div>

        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-5 bg-body rounded">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-star"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Familiar File Storage</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-6 mb-md-0 text-md">
                No need to learn new concepts. With no Web3 jargon, our SDK
                makes file storage as simple as you’re used to. Install it,
                integrate seamlessly, and start using it immediately. The files
                are arranged in a directory structure that is similar to Web2.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-5 bg-body rounded">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-download"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Optimized Data Transfer</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-6 mb-md-0 text-md">
                ExaDrive is built for efficient data transfer, minimizing
                bandwidth usage and reducing latency. This optimization ensures
                faster uploads and downloads, making it ideal for developers who
                need high-performance storage solutions.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-delay="600"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-5 bg-body rounded">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-sitemap"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Auto Scaling</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-6 mb-md-0 text-md">
                ExaDrive’s scalable architecture allows it to grow with your
                needs. Whether you’re handling small-scale storage or expanding
                rapidly, ExaDrive’s system adapts to ensure consistent
                performance, no matter the size of your project.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <!-- How It Works -->
  <section class="py-6 border-bottom bg-white">
    <div class="container">
      <div class="row">
        <div class="col-md-12 my-4">
          <h2 class="">ExaDrive Architecture</h2>
          <p class="">
            This architecture is representational only and is a simplified view.
          </p>
        </div>
      </div>

      <div class="row">
        <div
          class="col-md-12 py-6 mb-4"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-once="true"
        >
          <img
            :src="require('@/assets/img/complete_arc_trans_dark.svg')"
            alt=""
            class="img-fluid"
          />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p class="text-sm">
            ExaDrive leverages a decentralized architecture with mobile nodes as
            storage units and a Content Delivery Network (CDN) for rapid file
            distribution. Frequently accessed or public files are cached through
            multiple nodes to ensure low-latency delivery, while your data is
            securely stored across distributed storage clusters.
          </p>
        </div>
      </div>
    </div>
  </section>

  <!-- FAQ -->
  <section class="bg-white">
    <div class="container py-8">
      <div class="row">
        <div class="col-md-4" data-aos="fade-up" data-aos-once="true">
          <h2>Why ExaDrive?</h2>
          <p class="text-lg">
            ExaDrive offers a cutting-edge, scalable Web3 Distributed File
            System (DFS) built using mobile devices, designed to deliver the
            performance and ease developers need.
          </p>
          <p class="text-lg">
            ExaDrive combines the speed and familiarity of Web2 cloud storage
            with the power and scalability of Web3 DFS, making it the go-to
            choice for developers who need performance and simplicity in a
            decentralized system.
          </p>
        </div>
        <div
          class="col-md-8"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <Accordion :value="['0']" multiple>
            <AccordionPanel value="0">
              <AccordionHeader>Fastest Web3 DFS</AccordionHeader>
              <AccordionContent>
                <p class="m-0">
                  ExaDrive delivers Web2 cloud storage speeds in a Web3 DFS by
                  utilizing a Content Delivery Network (CDN) to quickly serve
                  files, just like traditional cloud solutions. Files are
                  securely and permanently stored on mobile devices, ensuring
                  both speed and security. With optimized data transfer, low
                  latency, and scalable architecture, ExaDrive provides
                  unmatched performance in decentralized file storage.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="1">
              <AccordionHeader>Unique Mobile First Approach</AccordionHeader>
              <AccordionContent>
                <p class="m-0">
                  ExaDrive leverages a global network of mobile devices to
                  create a fast, secure, and scalable decentralized file system.
                  Unlike traditional PC-first approaches, which risk being
                  dominated by large server farms, ExaDrive’s mobile-first
                  design ensures true decentralization. This approach
                  accelerates data distribution while securing files across a
                  wide array of devices, delivering unmatched speed,
                  reliability, and security in Web3 storage without the risk of
                  centralization.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="2">
              <AccordionHeader
                >Easy billing using our native token $XAP</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0">
                  Simplify your payments with our ERC-20, $XAP, and enjoy
                  seamless billing based on actual usage. Billing is calculated
                  per MB of data transfer, ensuring you only pay for what you
                  use. With fast, transparent transactions and flexible control,
                  $XAP makes managing your costs effortless and efficient. Stay
                  in control of your expenses with precise, usage-based billing!
                </p>
              </AccordionContent>
            </AccordionPanel>
          </Accordion>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <!-- CTA -->
  <section
    class="py-7 py-md-8 bg-gradient-dark"
    :style="{
      'background-image': 'url(' + BGCTA + ')',
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
      'background-position': 'bottom',
    }"
  >
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-6 col-lg-6 mt-4">
          <!-- Heading -->
          <h1 class="display-4 text-white">
            Web3 Project? <br />Use ExaDrive.
          </h1>
          <p class="text-white text-lg mt-3">
            The future of internet is decentralized infra. Get started today
            with decentralized storage using ExaDrive!
          </p>

          <!-- Button -->
          <a
            href="/authentication/signup"
            class="btn btn-primary btn-lg lift mt-4"
          >
            Get Started &nbsp; <i class="fa fa-arrow-right"></i>
          </a>
        </div>

        <div
          class="col-md-6 col-lg-6 mt-4"
          data-aos="zoom-out-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div class="card">
            <div class="card-body">
              <h3>Apply for grants!</h3>
              <p class="text-md">
                Become a part our Accelerate program and get upto 1000 $XAP to
                build your Web3 apps using ExaDrive!
              </p>
              <div class="text-center">
                <a
                  class="btn btn-primary btn-lg my-4"
                  href="https://forms.gle/KXmK68rQBfdpWKX6A"
                  target="_blank"
                  >Apply Now</a
                >
              </div>

              <h6 class="mt-3">About Accelerate</h6>
              <p>
                Accelerate program is for Web3 projects to build their apps on a
                decentralized cloud infrastructure.
              </p>
              <p class="text-xs mt-4">
                *Accelerate program is for a limited period only.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <!-- FOOTER -->
  <footer class="py-4 bg-gray-200">
    <div class="container">
      <div class="row pt-4">
        <div class="col-md-4 col-xs-12 col-sm-12">
          <img src="rounded.png" class="img-fluid footer-logo mb-4" />
          <h5>ExaDrive Management Console</h5>
          <p>Distributed File System Built Using Mobile Devices.</p>
          <p class="text-sm">© ExaProtocol.com. All rights reserved.</p>
        </div>
        <div class="col-md-4 col-xs-12 col-sm-12 mt-2">
          <h4>Quick Links</h4>
          <hr />
          <br />
          <p>
            <a href="https://www.exaprotocol.com" target="_blank">Main Site</a>
          </p>
          <p>
            <a href="https://www.exaprotocol.com/token" target="_blank"
              >Token</a
            >
          </p>
          <p>
            <a href="https://www.exaprotocol.com/exadrive" target="_blank"
              >ExaDrive</a
            >
          </p>
          <p>
            <a href="https://www.exaprotocol.com/research" target="_blank"
              >Research</a
            >
          </p>
          <p>
            <a href="https://www.exaprotocol.com/contact" target="_blank"
              >Contact</a
            >
          </p>
          <p>
            <a href="https://www.exaprotocol.com/blog" target="_blank">Blog</a>
          </p>
        </div>
        <div class="col-md-4 col-xs-12 col-sm-12 mt-2">
          <h4>Social</h4>
          <hr />
          <br />
          <p href="">
            <a href="https://t.me/exaofficial" target="_blank">Telegram</a>
          </p>
          <p href="">
            <a href="https://twitter.com/ExaProtocol" target="_blank"
              >X (Twitter)</a
            >
          </p>
          <p href="">
            <a
              href="https://www.youtube.com/channel/UC1p9HLM8np7KDstZdxvMpJw"
              target="_blank"
              >YouTube</a
            >
          </p>
        </div>
      </div>
    </div>
    <!-- / .container -->

    <div class="exa-powby">
      <a
        href="https://drive.exaprotocol.com/?ref=exa-powby-badge"
        target="_blank"
      >
        <img
          src="https://exa-protocol.exadrivecdn.com/assets/Powered by ExaDrive.png"
      /></a>
    </div>
  </footer>
</template>

<script>
import Navbar from "@/components/PageLayout/Navbar.vue";
import AppFooter from "@/components/PageLayout/Footer.vue";
import { mapMutations } from "vuex";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import DefaultCounterCard from "../../examples/Cards/DefaultCounterCard.vue";
import Globe from "../../examples/Globe.vue";
import Brands from "./componentrs/Brands.vue";
import BGGrid from "@/assets/img/bg-grid.svg";
import BGCube from "@/assets/img/bg-cube.svg";
import BGCTA from "@/assets/img/bg-cta.svg";
import BGFeatures from "@/assets/img/bg-features.png";
export default {
  name: "HomePage",
  components: {
    Navbar,
    AppFooter,
    Accordion,
    AccordionPanel,
    AccordionHeader,
    AccordionContent,
    DefaultCounterCard,
    Globe,
    Brands,
  },
  data() {
    return {
      BGGrid: BGGrid,
      BGCube: BGCube,
      BGCTA: BGCTA,
      BGFeatures: BGFeatures,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {},
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>

<style>
.footer-logo {
  width: 60px;
}
.hero-logo {
  width: 80px;
}

.exa-powby {
  width: 160px;
  height: auto;
  position: fixed;
  bottom: 40px;
  right: 0px;
  z-index: 100000;
}
.exa-powby img {
  width: 100%;
  border-radius: 10px 0px 0px 10px;
}
</style>
