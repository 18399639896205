<template>
  <div class="container-fluid mt-3 py-4 bg-gradient-dark">
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p>
              <a href="/admin/users"
                ><i class="fa fa-arrow-left"></i> Back to All Users</a
              >
            </p>
            <p>
              Administration / View User - {{ this.$route.params.userID }} |
              {{ completeProfile.user.email }}
            </p>

            <p>Org Name: {{ completeProfile.org.orgName || "-" }}</p>
            <p>Org About: {{ completeProfile.org.orgAbout || "-" }}</p>
            <p>
              Address: {{ completeProfile.org.orgAddress || "-" }},
              {{ completeProfile.org.orgCountry || "-" }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5 mt-4">
      <div class="col-md-12" data-aos="fade-up">
        <div class="card">
          <div class="card-body py-4">
            <div class="row">
              <div class="col-md-12">
                <p>All Apps</p>
              </div>
              <div class="col-md-4 mt-4" v-for="app in apps" :key="app._id">
                <AppCardAdmin :app="app" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import axios from "axios";
import AppCardAdmin from "./components/AppCardAdmin.vue";
export default {
  name: "ViewUser",
  components: {
    AppCardAdmin,
  },
  data() {
    return {
      apps: [],
      completeProfile: {
        user: {
          email: "",
        },
        org: {},
      },
    };
  },
  mounted() {
    this.$store.state.isAbsolute = false;
    this.$store.state.isNavFixed = false;
    setNavPills();

    //getUser
    axios
      .get(
        this.$store.getters["getServerURL"] +
          "/admin/getUser/" +
          this.$route.params.userID,
        {
          headers: { Authorization: localStorage.getItem("jwt") },
        }
      )
      .then((response) => {
        this.completeProfile = response.data;
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    //fetch apps
    axios
      .get(
        this.$store.getters["getServerURL"] +
          "/admin/getAllApps/" +
          this.$route.params.userID,
        {
          headers: { Authorization: localStorage.getItem("jwt") },
        }
      )
      .then((response) => {
        this.apps = response.data;
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
