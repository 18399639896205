<template>
  <div class="container-fluid mt-3 py-4 bg-gradient-dark">
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h3>Administration</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5 mt-4">
      <div class="row">
        <div class="col-lg-4" data-aos="fade-up">
          <div class="card">
            <div class="card-body py-4">
              <ul class="quick-links">
                <li>
                  <span class="fa fa-arrow-right"></span>
                  &nbsp; <a href="/admin/users">View All Users</a>
                </li>
                <li>
                  <span class="fa fa-arrow-right"></span> &nbsp;
                  <a href="/admin/summary">Summary</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import axios from "axios";
export default {
  name: "Admin",
  components: {},
  mounted() {
    this.$store.state.isAbsolute = false;
    this.$store.state.isNavFixed = false;
    setNavPills();

    //fetch apps
    axios
      .get(this.$store.getters["getServerURL"] + "/org/apps/getAllApps", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        // console.log(response);
        this.loading = false;
        this.apps = response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      apps: [],
      loading: true,
    };
  },
};
</script>

<style>
.quick-links {
  list-style: none;
  padding: 0;
}

.quick-links li {
  padding: 10px;
}

.quick-links li a:hover {
  color: initial;
}
.quick-links li:hover {
  background: #eee;
}
</style>
